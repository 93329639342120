<template>
  <b-container
    class="py-3"
  >
    <b-row>
      <b-col
        cols="12"
        class="py-3"
      >
        <b-button
          v-for="(variant, key) in buttons"
          :key="key"
          :variant="variant"
          class="mr-2"
        >
          COLOURS
        </b-button>
      </b-col>
      <b-col
        cols="12"
        class="py-3"
      >
        <b-button
          v-for="(variant, key) in buttons"
          :key="key"
          :variant="variant"
          class="mr-2"
          disabled
        >
          COLOURS
        </b-button>
      </b-col>
      <b-col
        cols="12"
        class="py-3"
      >
        <b-button
          v-for="(variant, key) in buttons"
          :key="key"
          :variant="`outline-${variant}`"
          class="mr-2"
        >
          COLOURS
        </b-button>
      </b-col>
      <b-col
        cols="12"
        class="py-3"
      >
        <span
          v-for="(variant, key) in buttons"
          :key="key"
          :class="`mr-2 text-${variant}`"
        >
          COLOURS
        </span>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {

  name: 'UserDashboard',

  // middleware: ['auth', 'user'],
  middleware: ['guest'],

  metaInfo: () => ({
    title: 'Dashboard'
  }),

  data: () => ({
    buttons: [
      'primary', 'secondary', 'success', 'warning', 'danger', 'dark', 'light', 'info'
    ],
    texts: [

    ]
  })
}
</script>
